import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        this is rupali saini's official website
      </header>
    </div>
  );
}

export default App;
